import config from '../config';
import axiosInstance from '../helpers/axiosInstance';
import changePathName from '../helpers/changePathName';

const requestSmsCode = (context, router) => {
  const {
    purePhone,
    setError,
    setLoading,
    setAttemptsExhausted,
    setCodeRequestsLimit,
    setIsRequestSmsCodeSent
  } = context;

  const { queryPathNames: { auth } } = config;

  setLoading(true);

  axiosInstance.post(auth, {
    phone: purePhone
  })
    .then(response => {
      const pathname = '/phone-confirmation';
      const { code_requests_left: codeRequestsLeft } = response.data;
      setError(0);
      setCodeRequestsLimit(codeRequestsLeft < 1);
      return router.pathname !== pathname && changePathName(router, pathname);
    })
    .catch(errors => {
      const { response, request, message: errorsMessage } = errors;
      if (response) {
        const { error, message } = response.data;
        const failedAttempts = error === 'failed_attempts';
        const codeRequests = error === 'code_requests';
        const wrongPhone = error === 'phone';
        if (codeRequests || failedAttempts) {
          setError(1, message);
          setAttemptsExhausted(true);
        } else if (wrongPhone) {
          setError(2, message);
        } else {
          setError(4, message);
        }
      } else if (request) {
        setError(5);
      } else {
        console.log('Error', errorsMessage);
      }
    })
    .finally(() => {
      setLoading(false);
      setIsRequestSmsCodeSent(true);
    });
};

export default requestSmsCode;
