import config from '../config';

const phoneOperatorValidate = phone => {
  const { phoneOperatorFormat } = config;
  const opCode = phone.slice(0, 3);

  return opCode.match(phoneOperatorFormat);
};

export default phoneOperatorValidate;
