import axios from 'axios';
import base64url from 'base64url';
import i18n from '../utils/i18n';
import config from '../config';
import getUrlParameter from './getUrlParameter';

const returnToUrlEncoded = typeof window !== 'undefined' ? getUrlParameter('return_to') : null;
const returnToUrlDecoded = returnToUrlEncoded ? base64url.decode(returnToUrlEncoded) : '';
const returnToUrlIsStagingOrLocalhost = returnToUrlDecoded.match(/(azstaging\.net|azstage\.in|lvh\.me|localhost)/g);
const isRunAtLocalhost = typeof window !== 'undefined' ? window.location.origin.match(/(lvh\.me|localhost)/g) : false;
const isStaging = returnToUrlIsStagingOrLocalhost || isRunAtLocalhost;
const baseURL = config.apiBaseUrl[isStaging ? 'staging' : 'production'];

export default axios.create({
  baseURL,
  headers: {
    'Accept-Language': i18n.language
  }
});
