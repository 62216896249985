const createPhoneMask = () => {
  const phoneMask = (rawValue = '') => {
    let mask = [/\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
    let prefix = ['(', '0'];

    if (rawValue[0] === '0' || (rawValue[0] === '(' && rawValue[1] === '0')) {
      prefix = ['(', /\d/];
    }

    mask = prefix.concat(mask);

    return mask;
  };

  phoneMask.instanceOf = 'createNumberMask';

  return phoneMask;
};

export default createPhoneMask;
