import React from 'react';
import PropTypes from 'prop-types';

const FallbackInput = props => (
  <input
    {...props}
    className="input-group__field"
    type="tel"
  />
);

FallbackInput.defaultProps = {
  props: {}
};

FallbackInput.propTypes = {
  props: PropTypes.object
};

export default FallbackInput;
