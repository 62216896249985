import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ error }) => <div className="message message--error">{ error }</div>;

Error.propTypes = {
  error: PropTypes.string.isRequired
};

export default Error;
